import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
    mutation UserLogin($data: LoginInput!) {
        userLogin(input: $data) {
            access_token
        }
    }
`;

export const USER_LOGOUT = gql`
    mutation UserLogout {
        userLogout {
            status
            message
        }
    }
`;

export const USER_SEND_PASSWORD = gql`
    mutation UserSendPassword($id: ID!) {
        userSendPassword(id: $id) {
            label
            message
            user {
                id
            }
        }
    }
`;

export const USER_CHANGE_PASSWORD = gql`
    mutation UserChangePassword($input: UserChangePassword!) {
        userChangePassword(input: $input) {
            label
            message
        }
    }
`;

export const USER_UPDATE = gql`
    mutation UserUpdate($input: UserFieldsInput!) {
        userUpdate(input: $input) {
            label
            message
            user {
                id
                name
                initials
                nickname
                email
                status
                locale
                role
                brand
                brandArray
                gender
                avatar
                about
                itsMe
                relCompanies {
                    id
                    title
                }
                me_following
                is_online
                last_active_date
                last_visit_at
                dealer_id
                diamond_status
                salutation
                job_type
                seller_number
                phone
                created_at
                updated_at
            }
        }
    }
`;

export const USER_CREATE = gql`
    mutation UserCreate($input: UserFieldsInput!) {
        userCreate(input: $input) {
            label
            message
            user {
                id
                name
                initials
                nickname
                email
                status
                locale
                role
                brand
                brandArray
                gender
                avatar
                about
                itsMe
                relCompanies {
                    id
                    title
                }
                me_following
                is_online
                last_active_date
                last_visit_at
                dealer_id
                salutation
                job_type
                seller_number
                phone
                created_at
                updated_at
            }
        }
    }
`;

export const USER_IMPORT = gql`
    mutation UserImport($file: Upload!) {
        userImport(file: $file) {
            label
            message
        }
    }
`;

export const USER_STATISTICS_EXPORT_EXEL = gql`
    mutation UserStatisticsExportExel($date_from: String!, $date_to: String!) {
        userStatisticsExportExel(date_from: $date_from, date_to: $date_to) {
            label
            message
            file
        }
    }
`;

export const USER_DELETE = gql`
    mutation UserDelete($id: ID!) {
        userDelete(id: $id) {
            label
            message
        }
    }
`;

export const USER_QUESTION_CREATE = gql`
    mutation UserQuestionCreate($input: UserQuestionFieldsInput!) {
        userQuestionCreate(input: $input) {
            label
            message
        }
    }
`;

export const USER_QUESTION_UPDATE = gql`
    mutation UserQuestionUpdate($input: UserQuestionFieldsInput!) {
        userQuestionUpdate(input: $input) {
            label
            message
        }
    }
`;

export const USER_QUESTION_DELETE = gql`
    mutation UserQuestionDelete($id: ID!) {
        userQuestionDelete(id: $id) {
            label
            message
        }
    }
`;

export const USER_EXPORT_EXEL = gql`
    mutation UserExportExel {
        userExportExel {
            label
            message
            file
        }
    }
`;
